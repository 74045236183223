<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style> 
<template>
  <vx-card title="Monitoring COA">
    <div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
              <span>Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
              <div class="mt-4 flex justify-between">
                <date-range-picker
                  style="min-height: 40px"
                  class="w-full"
                  ref="picker"
                  opens="center"
                  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  v-model="date"
                  :linkedCalendars="true"
                >
                  <template v-slot:input="picker">
                    {{ globalDateFormat(picker.startDate) }} -
                    {{ globalDateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button
                  class="ml-4"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-x"
                  @click="
                    () => {
                      this.date = { startDate: null, endDate: null };
                    }
                  "
                >
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-text">
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <!-- <th width="7%">Action</th> -->
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.journals"
              :class="tr.class"
            >
              <div class="mt-4 flex justify-between">
                <vx-tooltip text="Show" class="mr-4">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-on:click.stop="handleShow(tr)"
                  />
                </vx-tooltip>
              </div>
              <vs-td>
                {{ tr.CoaCode + " - " + tr.CoaName }}
              </vs-td>
              <vs-td>
                {{ formatPrice(parseFloat(tr.Debit)) }}
              </vs-td>
              <vs-td>
                {{ formatPrice(parseFloat(tr.Credit)) }}
              </vs-td>
              <vs-td>
                {{ formatPrice(tr.Debit - tr.Credit) }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
        
         <!-- DETAIL PROMPT -->
            <div>
                <vs-popup title="Detail" color="primary" @close="closeDetail" :buttons-hidden="false"
                    :active.sync="seeDetailPrompt">
                        <div class="con-exemple-prompt">
                            <div class="popup-container" ref="popupContainer">
                                  <div class="tab-text">   
                                 
                                   <div> <h4>  {{this.CoaCode }} - {{this.CoaName}}</h4> </div>  <br>
                                  <data-table :responseData="responseDataApi" :propsParams="params" @reloadDataFromChild="reloadDataDetail" >
                                   <template slot="thead">
                                 
                                      <th>Operating Unit</th>
                                      <th>Debit</th>
                                      <th>Credit</th>
                                      <th>Variance</th>
                                  
                                   </template>
                                 <template slot="tbody">
                                      <tr :key="indextr" v-for="(tr, indextr) in responseDataApi.detail">
                                  
                                          <td>  {{ tr.OperatingUnitCode }} - {{ tr.OperatingUnitName }}  <br />     </td>
                                       <td>  {{ formatPrice(parseFloat(tr.Debit)) }} <br />     </td>
                                        <td>  {{ formatPrice(parseFloat(tr.Credit)) }} <br />     </td>
                                         <td>  {{ formatPrice(parseFloat (tr.Debit - tr.Credit))}} <br />      </td>
                                        <hr>
                                      </tr>
                                 </template>
                                  </data-table>
                                  </div>
                          
                        </div>
                    </div>
                </vs-popup>
            </div>

      </div>
    </div>
  </vx-card>
</template>
<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    DateRangePicker,
  },
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
        status: 0,
      },
      date: {
        startDate: null,
        endDate: null,
      },
      header: [
        {
          text: "",
          value: "",
          sortable: false,
          // width: '5%'
        },
        {
          text: "COA Name",
          value: "coa_name",
          sortable: false,
          // width: '5%'
        },
        {
          text: "Debit",
          value: "Debit",
          sortable: false,
        },
        {
          text: "Credit",
          value: "Credit",
          sortable: false,
        },
        {
          text: "Variance (Debit-Credit)",
          value: "Variance",
          sortable: false,
        },
      ],
      responseData: {},
      responseDataApi: {},
      // responseDataReconciled: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      seeDetailPrompt: false,
      seeDetailCoa:null,
      ID: null,
      CoaCode:null,
      CoaName:null,
    };
  },
  computed: {},
  watch: {
    "date.startDate"() {
      this.params.page = 1;
      this.reloadData(this.params);
    },
    "date.endDate"() {
      this.params.page = 1;
      this.reloadData(this.params);
    },
  },
  mounted() {
    console.log(this.selected);
    console.log("mounted");
    const today = new Date();
    this.date.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    this.date.endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.date.startDate = moment(this.date.startDate).format("YYYY-MM-DD");
    this.date.endDate = moment(this.date.endDate).format("YYYY-MM-DD");
  },
  methods: {
    handleBack() {
      this.$router.push("/finance/bank-statement");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },

    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = "";
      this.selectedData = {
        ID: 0,
      };
      // this.reloadData(this.params);
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/report/monitoring-coa", {
            params: {
              status: params.status,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,

              start_date: this.date.startDate
                ? moment(this.date.startDate).format("YYYY-MM-DD")
                : null,
              end_date: this.date.endDate
                ? moment(this.date.endDate).format("YYYY-MM-DD")
                : null,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    handleShow(tr){
      

            // this.reconcile = tr.Reconcile;
            // this.ID = tr.ID;
            // console.log("global ch", this.reconcile);
            this.CoaCode = tr.CoaCode;
            this.CoaName = tr.CoaName;
            this.reloadDataDetail(tr);
            const seeDetailCoa = this.dataTableDetail(tr);
            
            seeDetailCoa.then((r) => {
                this.seeDetailCoa = r.data.detail;
                this.responseDataApi = r.data;
                this.responseDataApi.length = r.data.length;
                this.recordsTotal = r.data.recordsTotal;
                console.log("r.data", r.data)
                this.seeDetailPrompt = true;
                this.$vs.loading.close();
                // console.log("this.seeDetailCoa", this.seeDetailCoa)
            })
            .catch((e) => {
            this.$vs.loading.close();
          });

            console.log("Ini tr", tr);
            // this.seeDetailPrompt = true;
      
    },
    dataTableDetail(params) {
      this.$vs.loading();
      console.log("ini params", params)
       return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/report/monitoring-coa-detail", {
            params: {
              status: params.status,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,

              start_date: this.date.startDate
                ? moment(this.date.startDate).format("YYYY-MM-DD")
                : null,
              end_date: this.date.endDate
                ? moment(this.date.endDate).format("YYYY-MM-DD")
                : null,
              coa_code: params.CoaCode
            },
          })
          .then((r) => {
            resolve(r);
            console.log(r)
            
          })
          .catch((e) => {
            reject(e);
            // this.$vs.loading.close();
          });
      });
        },
    
    handleClose(reload = true) {
      if (reload) {
        this.reloadData(this.params);
      }
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    reloadData(params) {
      this.handleClose(false);
      this.params = params;
      this.params.status = 0; //reconcile null (approve)
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.journals;
          this.responseData = r.data;
          console.log(r.data);
          this.responseData.length = r.data.journals.length;
          console.log("r.data.journals.length", r.data.journals.length);
          // this.checkedAll = false;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    reloadDataDetail(tr) {
      console.log("Masuk ke Fungsi reloadDataDetail")
      this.handleClose(false);
      // this.params = params;
      // this.params.status = 0; //reconcile null (approve)
      console.log("ini tr reloadDataDetail =============",tr);
      this.$vs.loading();

      const dataTableNew = this.dataTableDetail(tr);
      dataTableNew.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.detail;
          this.responseDataApi = r.data;
          console.log(r.data);
          this.responseDataApi.length = r.data.detail.length;
          console.log("r.data.detail.length", r.data.detail.length);
          // this.checkedAll = false;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
   
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}

.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}

.highlight > td {
  background-color: #d0cdf0;
}

.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}

.vs-dialog {
  max-width: 1000px;
}

.prompt-container {
  height: 400px;
  overflow-y: scroll;
}

.popup-container {
  height: 450px;
}

.prompt-message {
  margin-bottom: 10px;
}

</style>
